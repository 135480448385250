import { FC, useState, useEffect, useMemo } from 'react';
import cssClass from 'classnames';

import { Dropdown, Input } from '../../atoms';
import { UserAddressProps, SelectOptions } from './UserAddress.types';
import { StyledSection } from './UserAddress.styles';
import { SessionMap } from './UserAddress.schema';
import countriesStatesGql from './queries/countryAndState.graphql';
import { GI_FIELD_MAX_LENGTH, getZipCodeValidation } from '../MemberInformation/helper';

import {
  useQueryFetch,
  gql,
  transformResponse,
  getWindowSession,
  isNotStateApplicable,
  isBulgariFlowFn,
} from '../../utils';
import { constants, GQL_OPT_NAME } from '../../constants';

const { CANADA_COUNTRY_CODE, DEFAULT_LOCALE, IS_LOCALE_EL, MEXICO_COUNTRY_CODE, SPAIN_COUNTRY_CODE, USA_COUNTRY_CODE } =
  constants;

const isBulgariFlow = isBulgariFlowFn();

export const UserAddress: FC<UserAddressProps> = props => {
  const {
    formRef,
    countryRegion,
    addressLine1,
    addressLine2,
    city,
    state,
    postCode,
    zipCode,
    address,
    errorMessage,
    selectedCountry,
    setSelectedCountry,
    watch,
    setPostalCode,
    isHybridPage,
    optional,
    optionalState,
    zipCodeRegex,
    zipPostalInvalidMsg,
    isUpcomigResPage,
    selectedState,
    setSelectedState,
    showCountryInFullWidth = isBulgariFlow ? true : false, //TODO: Once AEM is ready, remove this condition
    ...rest
  } = props;

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [touchedCountry, setTouchedCountry] = useState<string>('');

  const sessionObject = getWindowSession();
  const { tripsXRequestedByHeader, locale } = transformResponse(sessionObject, SessionMap);
  const GET_COUNTRIES_STATES = gql`
    ${countriesStatesGql}
  `;
  const [getCountriesStates, { data }] = useQueryFetch(
    GET_COUNTRIES_STATES,
    GQL_OPT_NAME.countriesAndState,
    tripsXRequestedByHeader
  );

  const stateValidation = useMemo(() => {
    switch (selectedCountry) {
      case CANADA_COUNTRY_CODE:
        return {
          maxLength: 2,
        };
      default:
        return {
          maxLength: 4,
        };
    }
  }, [selectedCountry]);

  const zipCodeValidation = useMemo(() => {
    return getZipCodeValidation({ selectedCountry: selectedCountry, errorMessage: props.errorMessage.zipPostal });
  }, [selectedCountry]);

  const { codeText, validationsRules } = useMemo(() => {
    let validationsRules = constants.USER_ADDRESS_FORM_VALIDATION.AddressCity;
    if (selectedCountry === constants.CHINA_COUNTRY_CODE && sessionObject?.authenticated) {
      validationsRules = constants.USER_ADDRESS_FORM_VALIDATION.AddressCityState;
    } else {
      const validationCountries = constants.USER_ADDRESS_FORM_VALIDATION_COUTIRIES as {
        AllFields: [string];
        AddressCity: [string];
        City: [string];
        AddressCityCode: [string];
        AddressCityState: [string];
      };
      const keys = Object.keys(validationCountries) as [
        'AllFields' | 'AddressCity' | 'City' | 'AddressCityCode' | 'AddressCityState'
      ];
      keys.forEach((item: 'AllFields' | 'AddressCity' | 'City' | 'AddressCityCode' | 'AddressCityState'): void => {
        if (validationCountries[item].includes(selectedCountry)) {
          validationsRules = constants.USER_ADDRESS_FORM_VALIDATION[item];
        }
      });
    }
    switch (selectedCountry) {
      case constants.USA_COUNTRY_CODE:
        return {
          codeText: zipCode,
          validationsRules,
        };
      default:
        return {
          codeText: postCode,
          validationsRules,
        };
    }
  }, [selectedCountry]);

  const fetchState =
    selectedCountry === (constants.CHINA_COUNTRY_CODE || constants.USA_COUNTRY_CODE) ? selectedCountry : '';

  const hasStates = selectedCountry === constants.CHINA_COUNTRY_CODE || selectedCountry === constants.USA_COUNTRY_CODE;

  useEffect(() => {
    getCountriesStates({
      variables: {
        type: 'COUNTRIES',
        type2: selectedCountry === constants.CHINA_COUNTRY_CODE ? 'CHINA_STATE_OPTIONS' : 'STATE_OPTIONS',
      },
    });
  }, [fetchState]);

  useEffect(() => {
    if (data?.countryCodes) {
      setCountries([
        ...(locale === DEFAULT_LOCALE ? [{ label: constants.COUNTRY_CODE_USA, value: constants.DEFAULT_COUNTRY }] : []),
        ...(data?.countryCodes?.lookups
          ?.sort((a: { label: string }, b: { label: string }) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            return 0;
          })
          ?.map((item: { code: string; label: string; description: string }) => {
            return {
              label: item.label,
              value: item.code,
            };
          }) ?? []),
      ]);
    }
    if (data?.stateCode) {
      setStates(
        data?.stateCode?.lookups?.map((item: { code: string; label: string }) => ({
          label: item.label,
          value: item.code,
        }))
      );
    }
  }, [data]);

  const postalCode = String(watch?.('postalCode'));
  useEffect(() => {
    setPostalCode && setPostalCode(postalCode);
  }, [postalCode]);

  useEffect(() => {
    if (selectedCountry === touchedCountry) {
      formRef.current?.trigger(['line1', 'line2', 'city', 'stateProvince', 'postalCode']);
    }
  }, [selectedCountry, touchedCountry]);

  const selectCountry = (event: { target: { value: string } }) => {
    setSelectedCountry(event.target?.value);
    setTouchedCountry(event.target?.value);
    formRef.current?.setValue('stateProvince', '');
    setSelectedState && setSelectedState('');
  };

  const selectState = (event: { target: { value: string } }) => {
    setSelectedState && setSelectedState(event.target?.value);
  };

  const sectionClassName = cssClass(
    `bottomContainer grid-container ${
      locale === IS_LOCALE_EL ? 'EL-container' : `US-container ${selectedCountry}-container`
    }`,
    {
      'hybrid-section': isHybridPage,
      'upRes-section': isUpcomigResPage,
    }
  );

  const countryClassName = cssClass('country-field', {
    'full-width-country': showCountryInFullWidth,
  });

  const lineAddress = () => {
    return (
      <>
        <Input
          {...rest}
          validateReset
          isMandatoryField={validationsRules.addressLine1}
          registerOptions={{
            required: validationsRules.addressLine1 ? errorMessage.address1 : null,
          }}
          className="line1-field"
          id="line1"
          name="line1"
          label={addressLine1}
          varient="outlined"
          isErrorIcon={false}
          placeholder=""
          maxLength={GI_FIELD_MAX_LENGTH.addressLine1}
        />
        <Input
          {...rest}
          validateReset
          maxLength={GI_FIELD_MAX_LENGTH.addressLine2}
          className="line2-field"
          id="line2"
          name="line2"
          label={addressLine2}
          placeholder={optional}
          varient="outlined"
          isErrorIcon={false}
        />
      </>
    );
  };

  const stateDropdown = () => {
    const requiredErrorMessageKey = selectedCountry === USA_COUNTRY_CODE ? 'usaState' : 'state';
    return (
      <Dropdown
        {...rest}
        isMandatoryField={validationsRules.state}
        registerOptions={{
          required: validationsRules.state ? props.errorMessage[requiredErrorMessageKey] : '',
        }}
        className="state-field"
        id="stateProvince"
        name="stateProvince"
        label={state}
        varient="outlined"
        selectOptions={states}
        isErrorIcon={false}
        {...(isUpcomigResPage
          ? {
              placeholder: selectedState ? undefined : optionalState,
              onChange: selectState,
              defaultValue: selectedState,
            }
          : { placeholder: optionalState })}
      />
    );
  };

  const cityAddress = () => {
    return (
      <Input
        {...rest}
        validateReset
        isMandatoryField={validationsRules.city}
        registerOptions={{
          required: validationsRules.city ? errorMessage.city : null,
        }}
        className="city-field"
        id="city"
        name="city"
        label={city}
        varient="outlined"
        placeholder=""
        maxLength={GI_FIELD_MAX_LENGTH.city}
        isErrorIcon={false}
      />
    );
  };
  //zipcode
  const postalCodeAddress = () => {
    return (
      <Input
        {...rest}
        validateReset
        isMandatoryField={validationsRules.code}
        maxLength={GI_FIELD_MAX_LENGTH.postalCode}
        registerOptions={{
          required: validationsRules.code ? errorMessage.zipPostal : null,
          pattern: {
            value: RegExp(zipCodeRegex),
            message: zipPostalInvalidMsg,
          },
          ...zipCodeValidation,
        }}
        className="code-field"
        id="postalCode"
        name="postalCode"
        placeholder=""
        label={codeText}
        varient="outlined"
        isErrorIcon={false}
      />
    );
  };

  const stateAddress = () => {
    if (hasStates) {
      return stateDropdown();
    }
    return (
      <Input
        {...rest}
        validateReset
        isMandatoryField={validationsRules.state}
        registerOptions={{
          required: validationsRules.state ? errorMessage.state : null,
        }}
        className="state-field"
        id="stateProvince"
        name="stateProvince"
        label={state}
        placeholder=""
        varient="outlined"
        isErrorIcon={false}
        {...stateValidation}
      />
    );
  };

  /**
   * render method to update the User Address based on selected country
   * @returns JSX for selected country
   */
  const renderUserAddress = () => {
    // ordering Guest User Address Field according to selected country
    const orderGuestUserAddressField: Record<string, (() => JSX.Element)[]> = {
      [constants.CHINA_COUNTRY_CODE]: [stateAddress, cityAddress, lineAddress, postalCodeAddress],
      [constants.USA_COUNTRY_CODE]: [lineAddress, cityAddress, stateAddress, postalCodeAddress],
      [constants.JAPAN_COUNTRY_CODE]: [stateAddress, cityAddress, lineAddress, postalCodeAddress],
      [SPAIN_COUNTRY_CODE]: [postalCodeAddress, cityAddress, lineAddress],
      [MEXICO_COUNTRY_CODE]: [postalCodeAddress, cityAddress, lineAddress],
    };

    switch (true) {
      case selectedCountry in orderGuestUserAddressField:
        return orderGuestUserAddressField[selectedCountry].map(addressField => addressField());
      case isNotStateApplicable(selectedCountry):
        return (
          <>
            {lineAddress()}
            {postalCodeAddress()}
            {cityAddress()}
          </>
        );
      default:
        return orderGuestUserAddressField[constants.USA_COUNTRY_CODE].map(addressField => addressField());
    }
  };

  return (
    <StyledSection data-component-name="o-book-UserAddress" data-testid="UserAddress" className={sectionClassName}>
      {!isHybridPage && !isUpcomigResPage && (
        <>
          <div className="divider" />
          <div className="t-subtitle-xl">{address}</div>
        </>
      )}

      <Dropdown
        {...rest}
        id="country"
        className={countryClassName}
        label={countryRegion}
        selectOptions={countries}
        name="country"
        isMandatoryField={true}
        varient="outlined"
        onChange={selectCountry}
        placeholder=""
        defaultValue={selectedCountry}
      />
      {renderUserAddress()}
    </StyledSection>
  );
};
